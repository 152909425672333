import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReselector } from '../../utils/sharedHooks'
import { selectIsBasicPlan } from '../../reducers/subscription.slice'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getCurrentUser } from '../../selectors/user.selectors'
import { User } from '../../reducers/auth/userReducer'
import { filterNulls, useAppDispatch } from '../../utils/typeHelpers'
import { getTransactionsByType } from '../Transactions/transactions.selectors'
import {
  CancellationStepIdentifier,
  FaAvailableConnectionType,
  fetchTotalCompletedReconciliationCount,
  updateAutomaticCancellationAnalyticsRecord,
} from './userCancellation.slice'
import { useObjectFlagValue } from '@openfeature/react-sdk'
import { FEATURE_FLAG_KEYS } from '../OpenFeature'
import { selectNumberOfQuartersWithFederalEstimate } from '../Taxes/QuarterlyTaxEstimates/userTaxEstimates.selector'
import { isNil } from 'lodash'
import { centsToDollars, formatCurrency } from '../../utils/currencyHelpers'
import { fetchUserTransactions } from '../Transactions/transactions.slice'
import { fetchUserTaxEstimates } from '../Taxes/QuarterlyTaxEstimates/userTaxEstimates.slice'
import {
  selectEligibleForPlaidStatements,
  selectFinancialAccounts,
} from '../../selectors/financeSelectors'
import {
  fetchFinancialAccountsIfNeeded,
  getAccountUploadStatuses,
  StatementStatus,
  StatementStatusType,
} from '../../actions/financialAccountActions'
import { Grid } from 'semantic-ui-react'
import {
  Text,
  Card,
  GridRowColumn,
  Button,
  Link,
} from '../../components/BaseComponents'

export enum CANCELLATION_FLOW_STEP {
  savingMoneyAndTime = 'savingMoneyAndTime',
  costComparison = 'costComparison',
  discountOffer = 'discountOffer',
  cancellationComplete = 'cancellationComplete',
  cancellationPrevented = 'cancellationPrevented',
}

export interface CancellationFlowStaticValues {
  bookkeeping_with_expert_monthly_fee_in_cents?: number
  time_saved_per_account_reconciliation_in_seconds?: number
  time_saved_per_qte_calculation_in_seconds?: number
  catch_up_bookkeeping_monthly_fee_in_cents?: number
  s_corp_election_support_monthly_fee_in_cents?: number
  average_money_saved_in_cents?: number
  effective_tax_rate?: number
  annual_business_tax_preparation_in_cents?: number
  average_time_saved_in_seconds?: number
  time_saved_per_transaction_categorization_in_seconds?: number
  minimum_money_threshold_in_cents?: number
  cpa_support_monthly_fee_in_cents?: number
  annual_personal_tax_preparation_monthly_fee_in_cents?: number
  minimum_time_threshold_in_seconds?: number
  average_number_of_reconciliations_per_year?: number
  average_number_of_transactions_per_year?: number
  monthly_subscription_discount_code?: string
  yearly_subscription_discount_code?: string
}

export const useGetSaveMoneyAndTimePageData = (recordId?: number) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const cancellationSaveFlowStaticValues: CancellationFlowStaticValues =
    useObjectFlagValue(FEATURE_FLAG_KEYS.cancellationSaveFlowStaticValues, {})

  const [showPersonalized, setShowPersonalized] = useState<boolean>()
  const [saveTimeAndMoneyData, setSaveTimeAndMoneyData] = useState<{
    moneySavedInCents: number
    timeSavedInSeconds: number
    totalReconciliations: number
    totalCategorizedTransactions: number
  }>()
  const [accountUploadStatuses, setAccountUploadStatuses] =
    useState<StatementStatus[]>()

  useEffect(() => {
    dispatch(fetchUserTransactions())
    dispatch(fetchUserTaxEstimates())
    dispatch(fetchFinancialAccountsIfNeeded())
  }, [dispatch])

  // For saving time and money
  const businessTransactions = useReselector(
    getTransactionsByType,
    'businessExpenses'
  )

  const allCategorizedTransactions = useReselector(
    getTransactionsByType,
    'categorized'
  )

  const quartersWithEstimates = useReselector(
    selectNumberOfQuartersWithFederalEstimate
  )
  const getSaveMoneyAndTimeData = useCallback(async () => {
    const {
      time_saved_per_account_reconciliation_in_seconds,
      time_saved_per_qte_calculation_in_seconds,
      effective_tax_rate,
      time_saved_per_transaction_categorization_in_seconds,
    } = cancellationSaveFlowStaticValues

    const totalReconciliations =
      (await fetchTotalCompletedReconciliationCount()(dispatch)) ?? 0

    const totalCategorizedTransactions = allCategorizedTransactions.length

    //if the static values aren't set, default to 0 so that we show the default
    //"average" user saves ... message
    if (
      !time_saved_per_account_reconciliation_in_seconds ||
      !time_saved_per_qte_calculation_in_seconds ||
      !effective_tax_rate ||
      !time_saved_per_transaction_categorization_in_seconds
    ) {
      return {
        moneySavedInCents: 0,
        timeSavedInSeconds: 0,
        totalReconciliations,
        totalCategorizedTransactions,
      }
    }

    const businessTransactionsTotal = businessTransactions.reduce(
      (total, transaction) => total + transaction.amountInCents,
      0
    )

    const moneySavedInCents = Math.ceil(
      businessTransactionsTotal * effective_tax_rate
    )

    const timeSavedInSeconds =
      totalCategorizedTransactions *
        time_saved_per_transaction_categorization_in_seconds +
      totalReconciliations * time_saved_per_account_reconciliation_in_seconds +
      quartersWithEstimates * time_saved_per_qte_calculation_in_seconds
    return {
      moneySavedInCents,
      timeSavedInSeconds,
      totalReconciliations,
      totalCategorizedTransactions,
    }
  }, [
    dispatch,
    cancellationSaveFlowStaticValues,
    businessTransactions,
    allCategorizedTransactions,
    quartersWithEstimates,
  ])

  const showPersonalizedSavingsPage = useCallback(async () => {
    const data = await getSaveMoneyAndTimeData()
    const {
      minimum_money_threshold_in_cents,
      minimum_time_threshold_in_seconds,
    } = cancellationSaveFlowStaticValues

    return (
      !isNil(minimum_money_threshold_in_cents) &&
      !isNil(minimum_time_threshold_in_seconds) &&
      data.moneySavedInCents > minimum_money_threshold_in_cents &&
      data.timeSavedInSeconds > minimum_time_threshold_in_seconds
    )
  }, [getSaveMoneyAndTimeData, cancellationSaveFlowStaticValues])

  const saveTimeAndMoneyValues = useMemo(() => {
    if (!saveTimeAndMoneyData) {
      return null
    }

    const {
      average_money_saved_in_cents,
      average_time_saved_in_seconds,
      average_number_of_reconciliations_per_year,
      average_number_of_transactions_per_year,
    } = cancellationSaveFlowStaticValues

    // if the average data isn't on the flag, we have no choice but to show their actual data
    const showPersonalizedPage =
      showPersonalized &&
      !isNil(average_money_saved_in_cents) &&
      !isNil(average_time_saved_in_seconds)

    const {
      moneySavedInCents,
      timeSavedInSeconds,
      totalReconciliations,
      totalCategorizedTransactions,
    } = saveTimeAndMoneyData

    const dollarsSaved = formatCurrency(
      Math.ceil(
        centsToDollars(
          showPersonalizedPage || !average_money_saved_in_cents
            ? moneySavedInCents
            : average_money_saved_in_cents
        )
      ),
      { hideDecimalsIfZero: true }
    )

    const timeSaved =
      showPersonalizedPage ||
      !average_money_saved_in_cents ||
      !average_time_saved_in_seconds
        ? timeSavedInSeconds
        : average_time_saved_in_seconds

    const timeString = Math.ceil(timeSaved / 3600).toLocaleString()

    const transactionsCount = showPersonalizedPage
      ? totalCategorizedTransactions
      : average_number_of_transactions_per_year
    const reconciliationsCount = showPersonalizedPage
      ? totalReconciliations
      : average_number_of_reconciliations_per_year

    return {
      dollarsSaved,
      timeString,
      transactionsCount,
      reconciliationsCount,
      showPersonalizedPage,
    }
  }, [showPersonalized, saveTimeAndMoneyData, cancellationSaveFlowStaticValues])

  //For financial account alert
  const eligibleForPlaidStatements = useReselector(
    selectEligibleForPlaidStatements
  )

  const financialAccounts = useReselector(selectFinancialAccounts)

  const faAccountAlertType = useMemo(() => {
    const lbaEligibleAccountIds = accountUploadStatuses
      ?.filter(
        (status) => status.status === StatementStatusType.LIMITED_BANK_ACCESS
      )
      .map((status) => status.id)
    const showLba = Object.values(financialAccounts).some(
      (account) =>
        lbaEligibleAccountIds?.includes(account.id) &&
        account.bankAccessEnabledAt === null
    )

    const noSupport = accountUploadStatuses?.every(
      (status) => status.status === StatementStatusType.NO_SUPPORT
    )
    if (eligibleForPlaidStatements) {
      return FaAvailableConnectionType.PLAID_STATEMENTS
    } else if (showLba) {
      return FaAvailableConnectionType.LBA
    } else if (noSupport) {
      return FaAvailableConnectionType.CREATE_NEW_ACCOUNT
    } else {
      return undefined
    }
  }, [eligibleForPlaidStatements, accountUploadStatuses, financialAccounts])

  const financialAccountAlert = useMemo(() => {
    if (!faAccountAlertType) {
      return null
    }
    let alertBody
    let alertButton

    switch (faAccountAlertType) {
      case FaAvailableConnectionType.PLAID_STATEMENTS: {
        alertBody =
          'Connect your bank through Stripe to automatically upload all your transactions each month.'
        alertButton = (
          <Button
            variant="secondary"
            onClick={() => {
              localStorage.setItem('plaidStatementReloginModalShown', 'false')
              if (recordId) {
                dispatch(
                  updateAutomaticCancellationAnalyticsRecord(recordId, {
                    finalStepReached:
                      CancellationStepIdentifier.FINANCIAL_ACCOUNT_UPDATE_CLICKED,
                  })
                )
              }

              navigate('/accounts')
            }}
          >
            Connect bank
          </Button>
        )
        break
      }
      case FaAvailableConnectionType.LBA: {
        alertBody =
          'You can grant Heard limited bank access through your bank, and it will allow us to automatically upload your statements each month.'
        //update with proper link
        alertButton = (
          <Button
            variant="secondary"
            onClick={() => {
              if (recordId) {
                dispatch(
                  updateAutomaticCancellationAnalyticsRecord(recordId, {
                    finalStepReached:
                      CancellationStepIdentifier.FINANCIAL_ACCOUNT_UPDATE_CLICKED,
                  })
                )
              }

              navigate('/accounts')
            }}
          >
            Connect bank
          </Button>
        )
        break
      }
      case FaAvailableConnectionType.CREATE_NEW_ACCOUNT: {
        alertBody =
          'By opening up a separate business bank account that is supported by Stripe, we can save your time by automatically uploading your statements each month.'
        //update with proper link
        alertButton = (
          <Link to={'www.joinheard.com'}>
            <Button
              onClick={() => {
                if (recordId) {
                  dispatch(
                    updateAutomaticCancellationAnalyticsRecord(recordId, {
                      finalStepReached:
                        CancellationStepIdentifier.FINANCIAL_ACCOUNT_UPDATE_CLICKED,
                    })
                  )
                }
              }}
              className="secondary"
            >
              Learn more
            </Button>
          </Link>
        )
        break
      }
      default:
        break
    }

    return (
      <Card backgroundColor="natural">
        <Grid>
          <GridRowColumn>
            <Text as="h3">
              Save even more time with auto-upload of monthly statements
            </Text>
          </GridRowColumn>
          <GridRowColumn>
            <Text as="bodyMd">{alertBody}</Text>
          </GridRowColumn>
          <GridRowColumn>{alertButton}</GridRowColumn>
        </Grid>
      </Card>
    )
  }, [faAccountAlertType, dispatch, recordId, navigate])

  useEffect(() => {
    const fetchData = async () => {
      const showPersonalizedVariable = await showPersonalizedSavingsPage()
      setShowPersonalized(showPersonalizedVariable)
      const saveTimeAndMoneyDataVariable = await getSaveMoneyAndTimeData()
      setSaveTimeAndMoneyData(saveTimeAndMoneyDataVariable)
      const statusesResponse = await dispatch(getAccountUploadStatuses())
      if (statusesResponse) {
        setAccountUploadStatuses(statusesResponse)
      }
    }
    fetchData()
  }, [showPersonalizedSavingsPage, getSaveMoneyAndTimeData, dispatch])

  return {
    ...saveTimeAndMoneyValues,
    faAccountAlertType,
    financialAccountAlert,
  }
}

export const useSelfServiceCancellationSteps = (
  currentStep: CANCELLATION_FLOW_STEP
) => {
  const [_, setSearchParams] = useSearchParams()
  const isBasicPlan = useReselector(selectIsBasicPlan)
  const user = useReselector(getCurrentUser)

  const calculateSteps = useCallback(
    ({ isBasicPlan, user }: { isBasicPlan: boolean; user: User }) => {
      return filterNulls([
        CANCELLATION_FLOW_STEP.savingMoneyAndTime,
        CANCELLATION_FLOW_STEP.costComparison,
        isBasicPlan || user.cancellationDiscountApplied
          ? CANCELLATION_FLOW_STEP.discountOffer
          : null,
        CANCELLATION_FLOW_STEP.cancellationComplete,
      ])
    },
    []
  )

  const goToNextStep = useCallback(() => {
    if (!user) {
      return
    }
    const steps = calculateSteps({ isBasicPlan, user })
    const currentStepIndex = steps.indexOf(currentStep)
    const nextStep = steps[currentStepIndex + 1]
    setSearchParams({ step: nextStep })
  }, [calculateSteps, isBasicPlan, user, currentStep, setSearchParams])

  return goToNextStep
}
