import { useLocation } from 'react-router-dom'
import { useAppDispatch } from '../../utils/typeHelpers'
import {
  CANCELLATION_FLOW_STEP,
  useGetSaveMoneyAndTimePageData,
  useSelfServiceCancellationSteps,
} from './helpers'
import { useMemo } from 'react'
import { Divider, Grid, Image } from 'semantic-ui-react'
import {
  FormikDropdown,
  FormikTextArea,
  getFieldName,
  GridRowColumn,
  makeReqStringSchema,
  Text,
} from '../../components/BaseComponents'
import { STRIPE_CANCELLATION_REASON_KEYS } from '../../actions/settings/billingActions'
import UserCancellationFlowFooter from './UserCancellationFlowFooter'
import { updateAutomaticCancellationAnalyticsRecord } from './userCancellation.slice'
import { FormikProvider, useFormik } from 'formik'

const cancellationReasonDropdownOptions = [
  {
    key: STRIPE_CANCELLATION_REASON_KEYS.missingFeatures,
    value: STRIPE_CANCELLATION_REASON_KEYS.missingFeatures,
    text: 'I need more features',
  },
  {
    key: STRIPE_CANCELLATION_REASON_KEYS.tooComplex,
    value: STRIPE_CANCELLATION_REASON_KEYS.tooComplex,
    text: 'Ease of use was less than expected',
  },
  {
    key: STRIPE_CANCELLATION_REASON_KEYS.lowQuality,
    value: STRIPE_CANCELLATION_REASON_KEYS.lowQuality,
    text: 'Quality was less than expected',
  },
  {
    key: STRIPE_CANCELLATION_REASON_KEYS.tooExpensive,
    value: STRIPE_CANCELLATION_REASON_KEYS.tooExpensive,
    text: 'It’s too expensive',
  },
  {
    key: STRIPE_CANCELLATION_REASON_KEYS.switchedService,
    value: STRIPE_CANCELLATION_REASON_KEYS.switchedService,
    text: 'I found an alternative',
  },
  {
    key: STRIPE_CANCELLATION_REASON_KEYS.customerService,
    value: STRIPE_CANCELLATION_REASON_KEYS.customerService,
    text: 'Customer service was less than expected',
  },
  {
    key: STRIPE_CANCELLATION_REASON_KEYS.unused,
    value: STRIPE_CANCELLATION_REASON_KEYS.unused,
    text: 'I no longer need it',
  },
  {
    key: STRIPE_CANCELLATION_REASON_KEYS.other,
    value: STRIPE_CANCELLATION_REASON_KEYS.other,
    text: 'Other reasons',
  },
]

const SavingMoneyAndTime = ({ recordId }: { recordId?: number }) => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  const pageData = useGetSaveMoneyAndTimePageData(recordId)

  const saveTimeAndMoneyContent = useMemo(() => {
    if (!pageData) {
      return null
    }

    const {
      dollarsSaved,
      timeString,
      transactionsCount,
      reconciliationsCount,
      showPersonalizedPage,
    } = pageData

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/magnifying-glass-checkmark-2.svg"
              style={{ height: 80, width: 80 }}
            />
          </Grid.Column>
          <Grid.Column width={13}>
            <Grid>
              <GridRowColumn>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                  }}
                >
                  <Text as="display" color="green">
                    {dollarsSaved}
                  </Text>
                  <Text
                    style={{ marginLeft: '6px', marginBottom: '8px' }}
                    color="green"
                  >
                    saved
                  </Text>
                </div>
              </GridRowColumn>
              <GridRowColumn short>
                <Text as="bodyLg" color="forest">
                  Identifying and maximizing tax deductions
                </Text>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={3}>
            <Image
              src="https://heard-images.s3.amazonaws.com/assets/Clock.svg"
              style={{ height: 80, width: 80 }}
            />
          </Grid.Column>
          <Grid.Column width={13}>
            <Grid>
              <GridRowColumn>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'end',
                  }}
                >
                  <Text as="display" color="green">
                    {timeString}
                  </Text>
                  <Text
                    style={{ marginLeft: '6px', marginBottom: '8px' }}
                    color="green"
                  >
                    {showPersonalizedPage
                      ? 'hours saved'
                      : 'hours saved each year'}
                  </Text>
                </div>
              </GridRowColumn>
              <GridRowColumn short>
                <Text as="bodyLg" color="forest">
                  {transactionsCount} transactions categorized
                </Text>
                <Text as="bodyLg" color="forest">
                  {reconciliationsCount} bank accounts reconciled
                </Text>
                <Text as="bodyLg" color="forest">
                  Quarterly tax payments calculations
                </Text>
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }, [pageData])

  const goToNextStep = useSelfServiceCancellationSteps(
    CANCELLATION_FLOW_STEP.savingMoneyAndTime
  )

  const formik = useFormik({
    initialValues: {
      cancellationReason: '',
      cancellationComment: '',
    },
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async ({
      cancellationComment,
      cancellationReason,
    }: {
      cancellationComment: string
      cancellationReason: string
    }) => {
      // if they refresh the page, we may lose the record Id since it's saved in state
      if (recordId) {
        await updateAutomaticCancellationAnalyticsRecord(
          location.state.recordId,
          {
            cancellationComment,
            cancellationReason,
          }
        )(dispatch)
      }
      goToNextStep()
    },
  })

  const { isValid, isSubmitting, submitForm } = formik

  return (
    <Grid>
      <GridRowColumn centerContent>
        <Image
          src="https://heard-images.s3.amazonaws.com/assets/Support.png"
          style={{ height: 180, width: 180 }}
        />
      </GridRowColumn>
      <GridRowColumn centerContent>
        <Text as="display2" textAlign="center">
          We&apos;re sorry to hear you want to leave Heard...
        </Text>
      </GridRowColumn>
      <Grid.Row>
        <Grid.Column width={8}>
          <Grid>
            <GridRowColumn>
              <Text as="h1">Saving you money and time</Text>
            </GridRowColumn>
            <GridRowColumn>
              <Text as="bodyLg">
                We would love to continue our journey together. Before you go,
                we wanted to share some key highlights of your membership.
              </Text>
            </GridRowColumn>
            {!pageData?.showPersonalizedPage && (
              <GridRowColumn>
                <Text as="bodyLg">On average, our members saved...</Text>
              </GridRowColumn>
            )}
          </Grid>
        </Grid.Column>
        <Grid.Column width={8}>
          <Grid>
            <GridRowColumn>{saveTimeAndMoneyContent}</GridRowColumn>
            <GridRowColumn />
            <GridRowColumn>{pageData.financialAccountAlert}</GridRowColumn>
          </Grid>
        </Grid.Column>
      </Grid.Row>
      <GridRowColumn />
      <Divider />
      <FormikProvider value={formik}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Text as="h1">How can we do better?</Text>
          </Grid.Column>
          <Grid.Column width={8}>
            <Grid>
              <GridRowColumn>
                <FormikDropdown
                  label="Why are you thinking of leaving Heard?"
                  name={getFieldName<typeof formik.values>(
                    'cancellationReason'
                  )}
                  options={cancellationReasonDropdownOptions}
                  placeholder="Select Topic"
                  required
                  fullWidth
                  schema={makeReqStringSchema()}
                />
              </GridRowColumn>
              <GridRowColumn>
                <FormikTextArea
                  name={getFieldName<typeof formik.values>(
                    'cancellationComment'
                  )}
                  placeholder="Let us know what we can do to make Heard better for all therapists"
                  label="Can you provide us more details?"
                  schema={makeReqStringSchema()}
                  required
                />
              </GridRowColumn>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </FormikProvider>
      <UserCancellationFlowFooter
        disabled={!isValid || isSubmitting}
        stayLabel="Nevermind"
        goToNextStep={submitForm}
      />
    </Grid>
  )
}

export default SavingMoneyAndTime
