import { useLocation, useSearchParams } from 'react-router-dom'
import { useScrollRef } from '../../utils/sharedHooks'
import { GridRowColumn } from '../../components/BaseComponents'
import { Container } from 'semantic-ui-react'
import { useEffect, useMemo } from 'react'
import { createIsSomeEnum } from '../../utils/typeHelpers'
import { CANCELLATION_FLOW_STEP } from './helpers'
import SavingMoneyAndTime from './SavingMoneyAndTime'
import CancellationPrevented from './CancellationPrevented'

const isStep = createIsSomeEnum(CANCELLATION_FLOW_STEP)
const SelfServeCancellationFlow = () => {
  const [searchParams] = useSearchParams()
  const { scrollRef, scrollToRef } = useScrollRef({ autoScroll: true })
  const location = useLocation()

  const recordId = location?.state?.recordId

  const stepParam = searchParams.get('step')
  const step = isStep(stepParam)
    ? stepParam
    : CANCELLATION_FLOW_STEP.savingMoneyAndTime

  useEffect(() => {
    scrollToRef()
  }, [scrollToRef])

  const content = useMemo(() => {
    switch (step) {
      case CANCELLATION_FLOW_STEP.savingMoneyAndTime:
        return <SavingMoneyAndTime recordId={recordId} />
      case CANCELLATION_FLOW_STEP.costComparison:
        return null //<CostComparison />
      case CANCELLATION_FLOW_STEP.discountOffer:
        return null //<DiscountOffer />
      case CANCELLATION_FLOW_STEP.cancellationComplete:
        return null //<CancellationComplete />
      case CANCELLATION_FLOW_STEP.cancellationPrevented:
        return <CancellationPrevented />
      default:
        return step satisfies never
    }
  }, [step, recordId])

  return (
    <Container variant="noShadow">
      <span ref={scrollRef} />
      <GridRowColumn>{content}</GridRowColumn>
    </Container>
  )
}

export default SelfServeCancellationFlow
