// /*
//   This is used to manage stripe payments
//
//   NOTE – API utilizes a copy of this file. If changes are made here, they must also be updated
//          within API
//
// */

const isProduction = process.env.VITE_IS_PROD === 'true'

/* 
  Pricing Updates 
  — Updated on Oct 3, 2022
  - April 21, 2023
    Clarification on naming - A "Product" on Stripe is referred to as a "Plan" in code
    Structure - implemented StripePlans, StripePlan, StripePlanDetails, StripePlanList
  — May 4, 2023
    Sets the correct monthly & annual production pricing for Basic Solo and Basic Group
  - Oct 4, 2023
    Adds products for the new subscription tiers
*/

export enum StripePlans {
  basic_solo = 'basic_solo',
  basic_group = 'basic_group',
}

export interface StripePlanDetails {
  // When monthly and annual prices are separate products
  productId?: string

  priceId: string
  amount: string
}

export interface StripePlan {
  // When monthly and annual prices are contained in the same product (Basic plan only)
  productId?: string
  productName?: string
  annual: StripePlanDetails
  monthly: StripePlanDetails
}

type StripePlanList = {
  [key in StripePlans]: StripePlan
}

const TEST_STRIPE_PLANS: StripePlanList = {
  /*
    These should NOT get deleted during cleanup. "Choose Plan" expects both to be hard-coded.
    These can be deleted after we sunset the Basic plans on Stripe.com
  */
  basic_solo: {
    productId: 'prod_Nlz5AMsS0MzNYt',
    annual: {
      priceId: 'price_1N0R7FJy69P78a4EX5bCnkuM',
      amount: '699',
    },
    monthly: {
      priceId: 'price_1N0R7FJy69P78a4EHx0bNIuT',
      amount: '69',
    },
  },
  basic_group: {
    productId: 'prod_Nlz7Wdg9VNQvno',
    annual: {
      priceId: 'price_1N0R8nJy69P78a4Eyc1UaRQ1',
      amount: '999',
    },
    monthly: {
      priceId: 'price_1N0R8nJy69P78a4Eu9LWP48Q',
      amount: '99',
    },
  },
}

const PROD_STRIPE_PLANS: StripePlanList = {
  /*
    These should NOT get deleted during cleanup. "Choose Plan" expects both to be hard-coded.
    These can be deleted after we sunset the Basic plans on Stripe.com
  */
  basic_solo: {
    productId: 'prod_Npbu9p0ENImpqa',
    annual: {
      priceId: 'price_1N3wgVJy69P78a4EbJJyJ7sh',
      amount: '1188',
    },
    monthly: {
      priceId: 'price_1N3wgVJy69P78a4EpW4qOnhV',
      amount: '119',
    },
  },
  basic_group: {
    productId: 'prod_NpbwnfSa8HiQ6z',
    annual: {
      priceId: 'price_1N3wiGJy69P78a4EHB6FCfaG',
      amount: '1788',
    },
    monthly: {
      priceId: 'price_1N3wiGJy69P78a4EdluBI3eu',
      amount: '179',
    },
  },
}
export const fetchStripePlans = () => {
  if (isProduction) {
    return PROD_STRIPE_PLANS
  } else {
    return TEST_STRIPE_PLANS
  }
}

export const CATCHUP_BOOKKEEPING_PRICE_PER_MONTH = 99
export const testCatchupBkPriceId = 'price_1PO7PGJy69P78a4EjM4pDmaA'
export const prodCatchupBkPriceId = 'price_1PO3m0Jy69P78a4EYiXdSuLO'
export const testTrialCatchupBkPriceId = 'price_1PyL1mJy69P78a4E4wPU6jkp'
export const prodTrialCatchupBkPriceId = 'price_1Q0RIyJy69P78a4EVzukHYgT'

const TEST_STRIPE_PAYROLL_PRICING = {
  payrollBase: {
    priceId: 'price_1LNfrVJy69P78a4El6OuHDOn',
  },
  payrollPerSeat: {
    priceId: 'price_1LNfuDJy69P78a4Eo8NetskP',
  },
}

const PROD_STRIPE_PAYROLL_PRICING = {
  payrollBase: {
    priceId: 'price_1LWou5Jy69P78a4EMqMu83GT',
  },
  payrollPerSeat: {
    priceId: 'price_1LWou5Jy69P78a4EDwPQBDkJ',
  },
}

export const fetchStripePayrollPrices = () => {
  if (isProduction) {
    return PROD_STRIPE_PAYROLL_PRICING
  } else {
    return TEST_STRIPE_PAYROLL_PRICING
  }
}
